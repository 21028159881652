export default [
  {
    path: "/iniciar-sesion/",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginPage.vue"),
  },
  {
    path: "/verificar-codigo",
    name: "2fa",
    component: () =>
      import(
        /* webpackChunkName: "verify-code" */ "../views/TwoFactorsAuth.vue"
      ),
  },
  {
    path: "/recuperar-contraseña",
    name: "recoverPassword",
    component: () =>
      import(
        /* webpackChunkName: "recover-password" */ "../views/RecoverPassword.vue"
      ),
  },
  {
    path: "/crear-cuenta",
    name: "signup",
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/CreateAccount.vue"),
  },
  {
    path: "/necesitas-validar-tu-email",
    name: "warningValidateEmail",
    component: () =>
      import(
        /* webpackChunkName: "warning-validate-email" */ "../views/WarningEmailNotValidated.vue"
      ),
  },
];
